<template>
    <div>
        <ValidationObserver ref="form">
            <form @submit.prevent="submit" @change="triggerProfileInfoFormChange">
                <div class="client-demo-card-header">
                    <h3>Assigned Dashboard</h3>
                </div>
                <div class="client-demo client-profile-form general px-4">
                    <div class="flex colx4 wrap">
                        <Dropdown name="Client Dashboard Builder" id="clientDemographicsChosenTheme" label="Choose Dashboard"
                            :options="dashboardBuildOptions" :disabled=isDropdownDisabled
                            :placeholder="`Select Dashboard`" v-model="clientProfileInfo.dashboardUsed"
                            @dropdownInput="dropdownChangeHandler" />

                    </div>
                </div>
            </form>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate';
import { clients } from '@/util/apiRequests';
import Input from '@/components/general/validatedInputs/Input.vue';
import Dropdown from '../general/validatedInputs/DropdownWithSearchInput';
import { dashboardBuilder } from '@/util/apiRequests';
import { Roles} from '@/util/globalConstants'
import { contactDetails } from '@/util/helpers/graphql/common.graphql.js';
import { sendReminderDashboardChangeNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';
export default {
    name: 'ClientDemographicsDashboardBuilder',
    components: { Dropdown, ValidationObserver, Input },
    props: {
        clientId: {
            type: [Number, String],
            default: 0
        }
    },
    data() {
        return {
             Roles,
             isDropdownDisabled: false,
            isProfileInfoFormChanged: false,
            dashboardBuildOptions: [],
            clientProfileInfo: {
                dashboardUsed: null
            },
            previousDashboard: null,
            clientContactDetails: null,
            clientData: null,
        };
    },
    async created() {
        if(this.userIsSuperAdmin()==false)
        {
            this.isDropdownDisabled = true;
        }
        await this.fetchDashboardList()
        await this.getAndSetClientProfileInfo()
        await this.getContactDetails();

    },
    methods: {
        userIsSuperAdmin() {
                if (this.$store.state.user.role_id==Roles.SUPER_ADMIN) {
                    return true;
                } else return false;
            },
        async getAndSetClientProfileInfo() {
            try {
                let res = await this.$api.get(clients.getClientProfileInfo(this.clientId))
                if (res.status === 200) {
                    const clientProfileDetails = this.dashboardBuildOptions.find(profile => profile.id === res.data[0].dashboardUsed);
                    if( clientProfileDetails ) {
                        const { id: dashboardUsed } = clientProfileDetails;
                        if (dashboardUsed)
                            this.clientProfileInfo = { dashboardUsed }
                            this.previousDashboard =  dashboardUsed 
                    }
                }
                else {
                    this.$toasted.error('Failed to fetch member theme and profile info')
                }
            }
            catch (err) {
                console.error(err)
                this.$toasted.error('Failed to fetch member profile and theme info')
            }

        },
        async getClient() {
            try {
                const res = await this.$api.get(clients.getClient(this.$route.params?.client_id));
                this.clientData = res.data?.[0];
                return true;
            } catch (error) {
                this.$toasted.error(error);
            }
        },
        async getContactDetails() {
            try {
                const temp = await this.getClient();
                if(temp){
                    const email = this.clientData.email;
                    const seniorContactDetails = await contactDetails( email );
                    this.clientContactDetails = {
                        familyId: seniorContactDetails.familyId,
                        contactId: seniorContactDetails.contactId
                    }
                }
            }
            catch( e ) {
                this.$toasted.error("Error while fetching Contact Details");
            }
        },
        async fetchDashboardList() {
            try {
                let result = await this.$api.get(dashboardBuilder.getTemplateList('completed'));
                if (result?.data?.templateMetaList) {
                    this.dashboardBuildOptions = result.data.templateMetaList.map(item => {
                        return {
                            id: item.id,
                            value: `${item.dashboardName}`,
                        }
                    })
                }
            }
            catch (err) {
                console.log("err", err)
            }
        },

        async submit() {
            const { dashboardUsed } = this.clientProfileInfo
            let result = null
            if (dashboardUsed)
                result = await this.hitPutEndpointWithData(clients.putClientProfileInfo(this.clientId), { dashboardUsed });
                if(this.previousDashboard != this.clientProfileInfo?.dashboardUsed){
                    try{
                        await sendReminderDashboardChangeNotification( this.clientContactDetails.familyId, this.clientContactDetails.contactId );
                        await this.getAndSetClientProfileInfo();
                    }catch(err){
                        this.$toaster.error("Failed to send Activity Reminder Notification");
                    }

                }
        },
        async hitPutEndpointWithData(endpoint, data) {
            const form = this.$refs.form;
            try {
                const res = await this.$api.put(endpoint, data);
                if (res.status === 400) {
                    form.setErrors(res.data);
                }
                if (res.status === 409) {
                    this.$toasted.error('Unable to save info, email is already in use.');
                }
                else {
                    if (this.$route.params?.client_id == 'new') {
                        this.assignId(res.data[0]);
                    }
                }

                return res.data;
            } catch (err) {
                this.$toasted.error('Could not save data.');
                return null;
            }
        },
        dropdownChangeHandler({ id, value }) {
            this.clientProfileInfo.dashboardUsed = id
            this.triggerProfileInfoFormChange()
        },
        triggerProfileInfoFormChange() {
            this.isProfileInfoFormChanged = true;
        },
    },
    watch: {
        clientProfileInfo: {
            handler() {
                this.$emit('clientProfileInfoChange', { context: this });
            },
            deep: true
        }
    },
    
};
</script>
